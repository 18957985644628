import React, { Fragment } from 'react';
import styled from 'styled-components';

import SEO from '../components/SEO';
import { PageWrapper } from '../components/Elements';
import { font, colors } from '../consts/style';

const Wrapper = styled.div`
  width: 100%;
  min-height: calc(100vh - 48.5rem);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h1 {
    ${font.h1};
    color: ${colors.yellow};
  }
  p {
    ${font.p};
    color: white;
  }
`;

const NotFoundPage = () => (
  <PageWrapper>
    <SEO title="404: Not found" />
    <Wrapper>
      <h1>404</h1>
      <p>Page not found</p>
    </Wrapper>
  </PageWrapper>
);

export default NotFoundPage;
